<template>
    <div class="container">
        <div class="periphery">
            <span>公司名称：</span>
            <div class="condition-style">
                <el-input
                        placeholder="请输入公司名称"
                        v-model="input2"
                        size="small"
                        clearable
                        @clear="search"
                        @keyup.enter.native="search"
                        style="width: 80%"
                >
                    <template slot="append">
                        <el-button
                                slot="append"
                                icon="el-icon-search"
                                @click="search"
                        ></el-button>
                    </template>
                </el-input>
                <el-button
                        type="warning"
                        size="small"
                        style="margin-left: 10px"
                        @click="reset"
                        icon="el-icon-refresh"
                >重置
                </el-button>
            </div>
        </div>

        <div class="periphery" style="margin-top: 15px">
            <div>
                <el-table stripe :data="list">
                    <el-table-column
                            label="公司"
                            prop="comName"
                            show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column label="项目" prop="projectName"> </el-table-column>
                    <el-table-column label="申请时间" prop="sendTime"></el-table-column>
                    <el-table-column label="有效时间" prop="validityTime"></el-table-column>
                </el-table>
            </div>
        </div>

        <el-pagination
                layout="total, sizes, prev, pager, next, jumper"
                background
                class="pagination"
                :total="total"
                :current-page="params.pageNum"
                :hide-on-single-page="true"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="params.pageSize"
                :page-sizes="[10, 20, 50, 100]"
        ></el-pagination>
    </div>
</template>

<script>
	import {messageBox} from "@/util/index";
	import {mapGetters} from "vuex";
	export default {
		name: "index",
		data() {
			return {
				input2: "",
				list: [],
				params: {
					pageNum: 1,
					pageSize: 10,
                    comName: "",
				},
				total: 0,
				title: "添加公告管理",
				dialogVisible: false,
				form: {
					title: "",
					delivery: true,
					date: null,
					content: null,
					source: ""
				},
				ruleForm: {}
			};
		},
		mounted() {
			this.getData(this.params);
		},
		methods: {
			/**
			 * 获取数据
			 * @param data
			 */
			getData(data) {
				this.get(this.config.apiUrl.authorization, data).then(res => {
					console.log(res.result.records)
					if (res.code == 200) {
						this.list = res.result.records;
						this.total = res.result.total;
					} else {
						this.msgError(res.message);
					}
				});
			},
			/**
			 * pageSize 每页条数
			 * @param val
			 */
			handleSizeChange(val) {
				this.params.pageSize = val;
				this.getData(this.params);
			},
			/**
			 * 当前页
			 * @param val
			 */
			handleCurrentChange(val) {
				this.params.pageNum = val;
				this.getData(this.params);
			},
			/**
			 * 条件搜索
			 */
			search() {
				this.params.comName = this.input2;
				this.getData(this.params);
			},
			/**
			 * 重置
			 */
			reset() {
				this.input2 = "";
                this.params.comName = this.input2;
                this.getData(this.params);
			},
			handleClose() {
				this.dialogVisible = false;
			},

		}
	};
</script>

<style lang="scss" scoped>
    .periphery {
        background-color: #fff;
        padding: 15px;
        border-radius: 5px;
    }

    /deep/ .ql-editor {
        height: 300px;
    }
</style>
